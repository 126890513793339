<template>
  <div class="index">
    <div class="content">
      <img class="logo" src="@/assets/logo.png" mode="aspectFit" />
      <div>
        <div class="title">一本关于城市的数据百科云笔记</div>
        <div class="title">即将上线</div>
      </div>
      <img class="banner" src="@/assets/banner.png" mode="widthFix" />
      <div class="footer">
        <div class="qrCodeBox">
          <div class="qrCodeItem">
            <img
              class="qrCode"
              src="@/assets/qrCode_mini.jpg"
              mode="widthFix"
            />
            <div>体验集城GDP小程序</div>
          </div>
          <div class="qrCodeItem">
            <img
              class="qrCode"
              src="@/assets/qrCode_weichat.png"
              mode="widthFix"
            />
            <div>关注微信公众号</div>
          </div>
        </div>

        <div class="mail">联系邮箱：linkcitynote@163.com</div>
        <div class="copyright">
          © 2024 苏州集城笔记信息科技有限公司&nbsp;&nbsp;版权所有&nbsp;&nbsp;
        </div>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >苏ICP备2022044265号-1</a
        >
        <a
          target="_blank"
          class="flex"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004268"
        >
          <img src="@/assets/gaba.png" class="gaba" mode="widthFix" />
          苏公网安备 32059002004268号
        </a>
      </div>
    </div>
  </div>
</template>

<script></script>

<style>
.index {
  background: #2d36e8;
  background-image: linear-gradient(
    to bottom,
    rgba(45, 54, 232, 1) 0%,
    rgba(4, 190, 254, 0.8) 100%
  );
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  color: white;
  padding-bottom: 24px;
}

.logo {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 160px;
}

.title {
  line-height: 35px;
  letter-spacing: 6px;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  padding: 0 8px;
}

.copyright {
  line-height: 24px;
  margin: 12px 0;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 40px;
}

.mail {
  margin: 16px 0;
  font-weight: bold;
}

a {
  color: white;
  text-decoration: none;
  line-height: 24px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.qrCode {
  width: 150px;
  margin-top: 24px;
  margin-bottom: 4px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .banner {
    width: 100%;
  }

  .gaba {
    height: 24px;
    margin-right: 4px;
  }
}

@media (min-width: 600px) {
  .banner {
    width: 500px;
  }

  .qrCodeBox {
    display: flex;
  }
  .qrCodeItem {
    margin: 16px;
  }

  .gaba {
    height: 16px;
    margin-right: 4px;
  }
}
</style>
